/* src/styles.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
[type=text],
input:where(:not([type])),
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
[type=text]:focus,
input:where(:not([type])):focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}
::-webkit-datetime-edit {
  display: inline-flex;
}
::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
[multiple],
[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
[type=checkbox],
[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type=checkbox] {
  border-radius: 0px;
}
[type=radio] {
  border-radius: 100%;
}
[type=checkbox]:focus,
[type=radio]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow);
}
[type=checkbox]:checked,
[type=radio]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
@media (forced-colors: active) {
  [type=checkbox]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
@media (forced-colors: active) {
  [type=radio]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=checkbox]:checked:hover,
[type=checkbox]:checked:focus,
[type=radio]:checked:hover,
[type=radio]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type=checkbox]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (forced-colors: active) {
  [type=checkbox]:indeterminate {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=checkbox]:indeterminate:hover,
[type=checkbox]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type=file] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type=file]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}
.cvc-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.cvc-pointer-events-none {
  pointer-events: none;
}
.cvc-pointer-events-auto {
  pointer-events: auto;
}
.cvc-fixed {
  position: fixed;
}
.cvc-absolute {
  position: absolute;
}
.cvc-relative {
  position: relative;
}
.cvc-inset-0 {
  inset: 0px;
}
.cvc-inset-x-0 {
  left: 0px;
  right: 0px;
}
.cvc-inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.cvc-bottom-0 {
  bottom: 0px;
}
.cvc-left-0 {
  left: 0px;
}
.cvc-left-2 {
  left: 0.5rem;
}
.cvc-left-3 {
  left: 0.75rem;
}
.cvc-right-0 {
  right: 0px;
}
.cvc-right-2 {
  right: 0.5rem;
}
.cvc-right-3 {
  right: 0.75rem;
}
.cvc-right-4 {
  right: 1rem;
}
.cvc-top-0 {
  top: 0px;
}
.cvc-top-1\/2 {
  top: 50%;
}
.cvc-top-2 {
  top: 0.5rem;
}
.cvc-top-4 {
  top: 1rem;
}
.cvc-z-50 {
  z-index: 50;
}
.cvc-z-\[100\] {
  z-index: 100;
}
.cvc--mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.cvc-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.cvc-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.cvc-mb-1 {
  margin-bottom: 0.25rem;
}
.cvc-ml-auto {
  margin-left: auto;
}
.cvc-mt-2 {
  margin-top: 0.5rem;
}
.cvc-mt-24 {
  margin-top: 6rem;
}
.cvc-mt-4 {
  margin-top: 1rem;
}
.cvc-mt-auto {
  margin-top: auto;
}
.cvc-block {
  display: block;
}
.cvc-flex {
  display: flex;
}
.cvc-inline-flex {
  display: inline-flex;
}
.cvc-grid {
  display: grid;
}
.cvc-aspect-square {
  aspect-ratio: 1 / 1;
}
.cvc-h-10 {
  height: 2.5rem;
}
.cvc-h-11 {
  height: 2.75rem;
}
.cvc-h-12 {
  height: 3rem;
}
.cvc-h-2 {
  height: 0.5rem;
}
.cvc-h-3\.5 {
  height: 0.875rem;
}
.cvc-h-4 {
  height: 1rem;
}
.cvc-h-5 {
  height: 1.25rem;
}
.cvc-h-6 {
  height: 1.5rem;
}
.cvc-h-7 {
  height: 1.75rem;
}
.cvc-h-8 {
  height: 2rem;
}
.cvc-h-9 {
  height: 2.25rem;
}
.cvc-h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height);
}
.cvc-h-auto {
  height: auto;
}
.cvc-h-full {
  height: 100%;
}
.cvc-h-px {
  height: 1px;
}
.cvc-max-h-96 {
  max-height: 24rem;
}
.cvc-max-h-screen {
  max-height: 100vh;
}
.cvc-min-h-\[80px\] {
  min-height: 80px;
}
.cvc-w-10 {
  width: 2.5rem;
}
.cvc-w-11 {
  width: 2.75rem;
}
.cvc-w-2 {
  width: 0.5rem;
}
.cvc-w-3\.5 {
  width: 0.875rem;
}
.cvc-w-3\/4 {
  width: 75%;
}
.cvc-w-4 {
  width: 1rem;
}
.cvc-w-5 {
  width: 1.25rem;
}
.cvc-w-7 {
  width: 1.75rem;
}
.cvc-w-9 {
  width: 2.25rem;
}
.cvc-w-\[100px\] {
  width: 100px;
}
.cvc-w-full {
  width: 100%;
}
.cvc-min-w-\[8rem\] {
  min-width: 8rem;
}
.cvc-min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width);
}
.cvc-shrink-0 {
  flex-shrink: 0;
}
.cvc-caption-bottom {
  caption-side: bottom;
}
.-cvc-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cvc-cursor-default {
  cursor: default;
}
.cvc-cursor-pointer {
  cursor: pointer;
}
.cvc-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.cvc-flex-col {
  flex-direction: column;
}
.cvc-flex-col-reverse {
  flex-direction: column-reverse;
}
.cvc-items-center {
  align-items: center;
}
.cvc-justify-center {
  justify-content: center;
}
.cvc-justify-between {
  justify-content: space-between;
}
.cvc-gap-1 {
  gap: 0.25rem;
}
.cvc-gap-1\.5 {
  gap: 0.375rem;
}
.cvc-gap-2 {
  gap: 0.5rem;
}
.cvc-gap-4 {
  gap: 1rem;
}
.cvc-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.cvc-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.cvc-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.cvc-overflow-auto {
  overflow: auto;
}
.cvc-overflow-hidden {
  overflow: hidden;
}
.cvc-whitespace-nowrap {
  white-space: nowrap;
}
.cvc-rounded-3xl {
  border-radius: 1.5rem;
}
.cvc-rounded-full {
  border-radius: 9999px;
}
.cvc-rounded-lg {
  border-radius: 0.5rem;
}
.cvc-rounded-md {
  border-radius: 0.375rem;
}
.cvc-rounded-sm {
  border-radius: 0.125rem;
}
.cvc-rounded-t-\[10px\] {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.cvc-border {
  border-width: 1px;
}
.cvc-border-2 {
  border-width: 2px;
}
.cvc-border-b {
  border-bottom-width: 1px;
}
.cvc-border-l {
  border-left-width: 1px;
}
.cvc-border-r {
  border-right-width: 1px;
}
.cvc-border-t {
  border-top-width: 1px;
}
.cvc-border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}
.cvc-border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.cvc-border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}
.cvc-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.cvc-border-red-500\/50 {
  border-color: rgb(239 68 68 / 0.5);
}
.cvc-border-transparent {
  border-color: transparent;
}
.cvc-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8);
}
.cvc-bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.cvc-bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.cvc-bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.cvc-bg-neutral-100\/50 {
  background-color: rgb(245 245 245 / 0.5);
}
.cvc-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.cvc-bg-transparent {
  background-color: transparent;
}
.cvc-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.cvc-fill-current {
  fill: currentColor;
}
.cvc-p-1 {
  padding: 0.25rem;
}
.cvc-p-4 {
  padding: 1rem;
}
.cvc-p-6 {
  padding: 1.5rem;
}
.cvc-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.cvc-px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.cvc-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.cvc-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.cvc-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.cvc-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.cvc-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.cvc-py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.cvc-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.cvc-pl-10 {
  padding-left: 2.5rem;
}
.cvc-pl-8 {
  padding-left: 2rem;
}
.cvc-pr-10 {
  padding-right: 2.5rem;
}
.cvc-pr-2 {
  padding-right: 0.5rem;
}
.cvc-pr-8 {
  padding-right: 2rem;
}
.cvc-pt-0 {
  padding-top: 0px;
}
.cvc-text-left {
  text-align: left;
}
.cvc-text-center {
  text-align: center;
}
.cvc-align-middle {
  vertical-align: middle;
}
.cvc-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.cvc-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.cvc-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.cvc-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.cvc-font-medium {
  font-weight: 500;
}
.cvc-font-semibold {
  font-weight: 600;
}
.cvc-leading-none {
  line-height: 1;
}
.cvc-tracking-tight {
  letter-spacing: -0.025em;
}
.cvc-tracking-widest {
  letter-spacing: 0.1em;
}
.cvc-text-current {
  color: currentColor;
}
.cvc-text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.cvc-text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.cvc-text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}
.cvc-text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}
.cvc-text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.cvc-text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}
.cvc-text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}
.cvc-text-neutral-950\/50 {
  color: rgb(10 10 10 / 0.5);
}
.cvc-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.cvc-underline-offset-4 {
  text-underline-offset: 4px;
}
.cvc-opacity-0 {
  opacity: 0;
}
.cvc-opacity-50 {
  opacity: 0.5;
}
.cvc-opacity-60 {
  opacity: 0.6;
}
.cvc-opacity-70 {
  opacity: 0.7;
}
.cvc-opacity-90 {
  opacity: 0.9;
}
.cvc-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.cvc-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.cvc-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.cvc-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.cvc-ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.cvc-ring-offset-white {
  --tw-ring-offset-color: #fff;
}
.cvc-transition {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    -webkit-backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    opacity,
    box-shadow,
    transform,
    filter,
    backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.cvc-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.cvc-transition-colors {
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.cvc-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.cvc-transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.cvc-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}
@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}
.cvc-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.file\:cvc-border-0::file-selector-button {
  border-width: 0px;
}
.file\:cvc-bg-transparent::file-selector-button {
  background-color: transparent;
}
.file\:cvc-text-sm::file-selector-button {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.file\:cvc-font-medium::file-selector-button {
  font-weight: 500;
}
.placeholder\:cvc-text-neutral-500::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}
.placeholder\:cvc-text-neutral-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}
.hover\:cvc-bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.hover\:cvc-bg-indigo-100\/80:hover {
  background-color: rgb(224 231 255 / 0.8);
}
.hover\:cvc-bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.hover\:cvc-bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.hover\:cvc-bg-neutral-100\/50:hover {
  background-color: rgb(245 245 245 / 0.5);
}
.hover\:cvc-bg-red-500\/80:hover {
  background-color: rgb(239 68 68 / 0.8);
}
.hover\:cvc-bg-red-500\/90:hover {
  background-color: rgb(239 68 68 / 0.9);
}
.hover\:cvc-text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}
.hover\:cvc-text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}
.hover\:cvc-text-neutral-950:hover {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}
.hover\:cvc-underline:hover {
  text-decoration-line: underline;
}
.hover\:cvc-opacity-100:hover {
  opacity: 1;
}
.focus\:cvc-bg-neutral-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.focus\:cvc-text-neutral-900:focus {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}
.focus\:cvc-opacity-100:focus {
  opacity: 1;
}
.focus\:cvc-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:cvc-ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:cvc-ring-indigo-950:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 27 75 / var(--tw-ring-opacity));
}
.focus\:cvc-ring-neutral-950:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(10 10 10 / var(--tw-ring-opacity));
}
.focus\:cvc-ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:cvc-outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-visible\:cvc-ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:
    var(--tw-ring-offset-shadow),
    var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:cvc-ring-indigo-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}
.focus-visible\:cvc-ring-neutral-950:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(10 10 10 / var(--tw-ring-opacity));
}
.focus-visible\:cvc-ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}
.focus-visible\:cvc-ring-offset-white:focus-visible {
  --tw-ring-offset-color: #fff;
}
.disabled\:cvc-pointer-events-none:disabled {
  pointer-events: none;
}
.disabled\:cvc-cursor-not-allowed:disabled {
  cursor: not-allowed;
}
.disabled\:cvc-opacity-50:disabled {
  opacity: 0.5;
}
.cvc-group:hover .group-hover\:cvc-opacity-100 {
  opacity: 1;
}
.cvc-group.destructive .group-\[\.destructive\]\:cvc-border-neutral-100\/40 {
  border-color: rgb(245 245 245 / 0.4);
}
.cvc-group.destructive .group-\[\.destructive\]\:cvc-text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:hover\:cvc-border-red-500\/30:hover {
  border-color: rgb(239 68 68 / 0.3);
}
.cvc-group.destructive .group-\[\.destructive\]\:hover\:cvc-bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:hover\:cvc-text-neutral-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:hover\:cvc-text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:focus\:cvc-ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:focus\:cvc-ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}
.cvc-group.destructive .group-\[\.destructive\]\:focus\:cvc-ring-offset-red-600:focus {
  --tw-ring-offset-color: #dc2626;
}
.cvc-peer:disabled ~ .peer-disabled\:cvc-cursor-not-allowed {
  cursor: not-allowed;
}
.cvc-peer:disabled ~ .peer-disabled\:cvc-opacity-70 {
  opacity: 0.7;
}
.data-\[disabled\]\:cvc-pointer-events-none[data-disabled] {
  pointer-events: none;
}
.data-\[side\=bottom\]\:cvc-translate-y-1[data-side=bottom] {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=left\]\:cvc--translate-x-1[data-side=left] {
  --tw-translate-x: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=right\]\:cvc-translate-x-1[data-side=right] {
  --tw-translate-x: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=top\]\:cvc--translate-y-1[data-side=top] {
  --tw-translate-y: -0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=checked\]\:cvc-translate-x-5[data-state=checked] {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=unchecked\]\:cvc-translate-x-0[data-state=unchecked] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=cancel\]\:cvc-translate-x-0[data-swipe=cancel] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=end\]\:cvc-translate-x-\[var\(--radix-toast-swipe-end-x\)\][data-swipe=end] {
  --tw-translate-x: var(--radix-toast-swipe-end-x);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[swipe\=move\]\:cvc-translate-x-\[var\(--radix-toast-swipe-move-x\)\][data-swipe=move] {
  --tw-translate-x: var(--radix-toast-swipe-move-x);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=checked\]\:cvc-border-indigo-500[data-state=checked] {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}
.data-\[state\=active\]\:cvc-bg-white[data-state=active] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[state\=checked\]\:cvc-bg-indigo-500[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.data-\[state\=open\]\:cvc-bg-neutral-100[data-state=open] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.data-\[state\=selected\]\:cvc-bg-neutral-100[data-state=selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.data-\[state\=unchecked\]\:cvc-bg-indigo-200[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}
.data-\[state\=active\]\:cvc-text-neutral-950[data-state=active] {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}
.data-\[state\=checked\]\:cvc-text-indigo-50[data-state=checked] {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.data-\[disabled\]\:cvc-opacity-50[data-disabled] {
  opacity: 0.5;
}
.data-\[state\=active\]\:cvc-shadow-sm[data-state=active] {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.data-\[swipe\=move\]\:cvc-transition-none[data-swipe=move] {
  transition-property: none;
}
.data-\[state\=closed\]\:cvc-duration-300[data-state=closed] {
  transition-duration: 300ms;
}
.data-\[state\=open\]\:cvc-duration-500[data-state=open] {
  transition-duration: 500ms;
}
.data-\[state\=open\]\:cvc-animate-in[data-state=open] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}
.data-\[state\=closed\]\:cvc-animate-out[data-state=closed] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[swipe\=end\]\:cvc-animate-out[data-swipe=end] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:cvc-fade-out-0[data-state=closed] {
  --tw-exit-opacity: 0;
}
.data-\[state\=closed\]\:cvc-fade-out-80[data-state=closed] {
  --tw-exit-opacity: 0.8;
}
.data-\[state\=open\]\:cvc-fade-in-0[data-state=open] {
  --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:cvc-zoom-out-95[data-state=closed] {
  --tw-exit-scale: .95;
}
.data-\[state\=open\]\:cvc-zoom-in-95[data-state=open] {
  --tw-enter-scale: .95;
}
.data-\[side\=bottom\]\:cvc-slide-in-from-top-2[data-side=bottom] {
  --tw-enter-translate-y: -0.5rem;
}
.data-\[side\=left\]\:cvc-slide-in-from-right-2[data-side=left] {
  --tw-enter-translate-x: 0.5rem;
}
.data-\[side\=right\]\:cvc-slide-in-from-left-2[data-side=right] {
  --tw-enter-translate-x: -0.5rem;
}
.data-\[side\=top\]\:cvc-slide-in-from-bottom-2[data-side=top] {
  --tw-enter-translate-y: 0.5rem;
}
.data-\[state\=closed\]\:cvc-slide-out-to-bottom[data-state=closed] {
  --tw-exit-translate-y: 100%;
}
.data-\[state\=closed\]\:cvc-slide-out-to-left[data-state=closed] {
  --tw-exit-translate-x: -100%;
}
.data-\[state\=closed\]\:cvc-slide-out-to-right[data-state=closed] {
  --tw-exit-translate-x: 100%;
}
.data-\[state\=closed\]\:cvc-slide-out-to-right-full[data-state=closed] {
  --tw-exit-translate-x: 100%;
}
.data-\[state\=closed\]\:cvc-slide-out-to-top[data-state=closed] {
  --tw-exit-translate-y: -100%;
}
.data-\[state\=open\]\:cvc-slide-in-from-bottom[data-state=open] {
  --tw-enter-translate-y: 100%;
}
.data-\[state\=open\]\:cvc-slide-in-from-left[data-state=open] {
  --tw-enter-translate-x: -100%;
}
.data-\[state\=open\]\:cvc-slide-in-from-right[data-state=open] {
  --tw-enter-translate-x: 100%;
}
.data-\[state\=open\]\:cvc-slide-in-from-top[data-state=open] {
  --tw-enter-translate-y: -100%;
}
.data-\[state\=open\]\:cvc-slide-in-from-top-full[data-state=open] {
  --tw-enter-translate-y: -100%;
}
.data-\[state\=closed\]\:cvc-duration-300[data-state=closed] {
  animation-duration: 300ms;
}
.data-\[state\=open\]\:cvc-duration-500[data-state=open] {
  animation-duration: 500ms;
}
.dark\:cvc-border-indigo-800:is([data-mode=dark] *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}
.dark\:cvc-border-neutral-800:is([data-mode=dark] *) {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}
.dark\:cvc-border-red-500:is([data-mode=dark] *) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.dark\:cvc-border-red-900:is([data-mode=dark] *) {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}
.dark\:cvc-border-red-900\/50:is([data-mode=dark] *) {
  border-color: rgb(127 29 29 / 0.5);
}
.dark\:dark\:cvc-border-red-900:is([data-mode=dark] *):is([data-mode=dark] *) {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}
.dark\:cvc-bg-indigo-50:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.dark\:cvc-bg-indigo-800:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.dark\:cvc-bg-indigo-950:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}
.dark\:cvc-bg-neutral-800:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.dark\:cvc-bg-neutral-800\/50:is([data-mode=dark] *) {
  background-color: rgb(38 38 38 / 0.5);
}
.dark\:cvc-bg-neutral-950:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}
.dark\:cvc-bg-red-900:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}
.dark\:cvc-text-indigo-50:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.dark\:cvc-text-indigo-950:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}
.dark\:cvc-text-neutral-400:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}
.dark\:cvc-text-neutral-50:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.dark\:cvc-text-neutral-50\/50:is([data-mode=dark] *) {
  color: rgb(250 250 250 / 0.5);
}
.dark\:cvc-text-red-900:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}
.dark\:cvc-ring-offset-neutral-950:is([data-mode=dark] *) {
  --tw-ring-offset-color: #0a0a0a;
}
.dark\:placeholder\:cvc-text-neutral-400:is([data-mode=dark] *)::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}
.dark\:placeholder\:cvc-text-neutral-400:is([data-mode=dark] *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}
.dark\:hover\:cvc-bg-indigo-100:hover:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}
.dark\:hover\:cvc-bg-indigo-800:hover:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.dark\:hover\:cvc-bg-indigo-800\/80:hover:is([data-mode=dark] *) {
  background-color: rgb(55 48 163 / 0.8);
}
.dark\:hover\:cvc-bg-neutral-800:hover:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.dark\:hover\:cvc-bg-neutral-800\/50:hover:is([data-mode=dark] *) {
  background-color: rgb(38 38 38 / 0.5);
}
.dark\:hover\:cvc-bg-red-900\/80:hover:is([data-mode=dark] *) {
  background-color: rgb(127 29 29 / 0.8);
}
.dark\:hover\:cvc-bg-red-900\/90:hover:is([data-mode=dark] *) {
  background-color: rgb(127 29 29 / 0.9);
}
.dark\:hover\:cvc-text-indigo-50:hover:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}
.dark\:hover\:cvc-text-neutral-50:hover:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.dark\:focus\:cvc-bg-neutral-800:focus:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.dark\:focus\:cvc-text-neutral-50:focus:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.dark\:focus\:cvc-ring-indigo-300:focus:is([data-mode=dark] *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}
.dark\:focus\:cvc-ring-neutral-300:focus:is([data-mode=dark] *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}
.dark\:focus-visible\:cvc-ring-neutral-300:focus-visible:is([data-mode=dark] *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}
.dark\:focus-visible\:cvc-ring-offset-neutral-950:focus-visible:is([data-mode=dark] *) {
  --tw-ring-offset-color: #0a0a0a;
}
.cvc-group.destructive .dark\:group-\[\.destructive\]\:cvc-border-neutral-800\/40:is([data-mode=dark] *) {
  border-color: rgb(38 38 38 / 0.4);
}
.cvc-group.destructive .dark\:group-\[\.destructive\]\:hover\:cvc-border-red-900\/30:hover:is([data-mode=dark] *) {
  border-color: rgb(127 29 29 / 0.3);
}
.cvc-group.destructive .dark\:group-\[\.destructive\]\:hover\:cvc-bg-red-900:hover:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}
.cvc-group.destructive .dark\:group-\[\.destructive\]\:hover\:cvc-text-neutral-50:hover:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.cvc-group.destructive .dark\:group-\[\.destructive\]\:focus\:cvc-ring-red-900:focus:is([data-mode=dark] *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}
.dark\:data-\[state\=active\]\:cvc-bg-neutral-950[data-state=active]:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}
.dark\:data-\[state\=checked\]\:cvc-bg-indigo-50[data-state=checked]:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}
.dark\:data-\[state\=open\]\:cvc-bg-neutral-800[data-state=open]:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.dark\:data-\[state\=selected\]\:cvc-bg-neutral-800[data-state=selected]:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.dark\:data-\[state\=unchecked\]\:cvc-bg-indigo-800[data-state=unchecked]:is([data-mode=dark] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}
.dark\:data-\[state\=active\]\:cvc-text-neutral-50[data-state=active]:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .sm\:cvc-bottom-0 {
    bottom: 0px;
  }
  .sm\:cvc-right-0 {
    right: 0px;
  }
  .sm\:cvc-top-auto {
    top: auto;
  }
  .sm\:cvc-max-w-sm {
    max-width: 24rem;
  }
  .sm\:cvc-flex-row {
    flex-direction: row;
  }
  .sm\:cvc-flex-col {
    flex-direction: column;
  }
  .sm\:cvc-justify-end {
    justify-content: flex-end;
  }
  .sm\:cvc-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:cvc-text-left {
    text-align: left;
  }
  .data-\[state\=open\]\:sm\:cvc-slide-in-from-bottom-full[data-state=open] {
    --tw-enter-translate-y: 100%;
  }
}
@media (min-width: 768px) {
  .md\:cvc-max-w-\[420px\] {
    max-width: 420px;
  }
}
.\[\&\:has\(\[role\=checkbox\]\)\]\:cvc-pr-0:has([role=checkbox]) {
  padding-right: 0px;
}
.\[\&\>span\]\:cvc-line-clamp-1 > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.\[\&\>svg\+div\]\:cvc-translate-y-\[-3px\] > svg + div {
  --tw-translate-y: -3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\>svg\]\:cvc-absolute > svg {
  position: absolute;
}
.\[\&\>svg\]\:cvc-left-4 > svg {
  left: 1rem;
}
.\[\&\>svg\]\:cvc-top-4 > svg {
  top: 1rem;
}
.\[\&\>svg\]\:cvc-text-neutral-950 > svg {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}
.\[\&\>svg\]\:cvc-text-red-500 > svg {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.dark\:\[\&\>svg\]\:cvc-text-neutral-50 > svg:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}
.dark\:\[\&\>svg\]\:cvc-text-red-900 > svg:is([data-mode=dark] *) {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}
.\[\&\>svg\~\*\]\:cvc-pl-7 > svg ~ * {
  padding-left: 1.75rem;
}
.\[\&\>tr\]\:last\:cvc-border-b-0:last-child > tr {
  border-bottom-width: 0px;
}
.\[\&_p\]\:cvc-leading-relaxed p {
  line-height: 1.625;
}
.\[\&_tr\:last-child\]\:cvc-border-0 tr:last-child {
  border-width: 0px;
}
.\[\&_tr\]\:cvc-border-b tr {
  border-bottom-width: 1px;
}
/*# sourceMappingURL=index.css.map */
@font-face {
font-family: '__geistSans_9f8872';
src: url(/_next/static/media/13ee043737a8bb36-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistSans_Fallback_9f8872';src: local("Arial");ascent-override: 93.76%;descent-override: 27.52%;line-gap-override: 0.00%;size-adjust: 107.19%
}.__className_9f8872 {font-family: '__geistSans_9f8872', '__geistSans_Fallback_9f8872'
}.__variable_9f8872 {--font-geist-sans: '__geistSans_9f8872', '__geistSans_Fallback_9f8872'
}

@font-face {
font-family: '__geistMono_1f0354';
src: url(/_next/static/media/4c33a6e82dbdb8e0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistMono_Fallback_1f0354';src: local("Arial");ascent-override: 76.43%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_1f0354 {font-family: '__geistMono_1f0354', '__geistMono_Fallback_1f0354'
}.__variable_1f0354 {--font-geist-mono: '__geistMono_1f0354', '__geistMono_Fallback_1f0354'
}

